.btn-outline-custom {
    color: #4437B8 !important; /* Custom text color *
     /* Custom border color */
     transition: all 0.3s ease-in-out;
}

.btn-outline-custom:hover {
    background-color: #4437B8  !important; /* Custom background color on hover */
    color: white !important; /* Custom text color on hover */
}

.btn-custom {
    color: #4437B8 !important; /* Custom text color */
    border: 1px solid #4437B8 !important; /* Custom border color */
    background-color: transparent; /* Transparent background */
    border-radius: 15px !important;
    transition: all 0.3s ease-in-out;
}

.btn-custom:hover {
    background-color: #4437B8 !important; /* Custom background color on hover */
    color: white !important; /* Custom text color on hover */
}